/* ==========================================================================
   FOOTER
   ========================================================================== */

.page__footer {
  @include clearfix;
  float: left;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  margin-top: 3em;
  color: $muted-text-color;
  -webkit-animation: $intro-transition;
  animation: $intro-transition;
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
  background-color: $footer-background-color;

  footer {
    @include clearfix;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    max-width: 100%;
    padding: 0 1em 2em;

    @include breakpoint($x-large) {
      max-width: $x-large;
    }
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .fas,
  .fab,
  .far,
  .fal {
    color: $muted-text-color;
  }
}

.page__footer-copyright {
  font-family: $global-font-family;
  font-size: $type-size-8;
}

.page__footer-follow {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    display: inline-block;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: $sans-serif-narrow;
    font-size: $type-size-6;
    text-transform: uppercase;
  }

  li + li:before {
    content: "";
    padding-right: 5px;
  }

  a {
    padding-right: 10px;
    font-weight: bold;
  }

  .social-icons {
    a {
      white-space: nowrap;
    }
  }
}
